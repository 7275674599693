<template>
  <div>
    <search-sms-reception-record @submit="submitSearch" />
    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :scroll="{ x: 'max-content' }"
      :pagination="false"
      @change="sortChange"
      row-key="id"
    >
      <div slot="msisdn" slot-scope="msisdn, record" v-if="isShowMenu('customer/sms_reception_record/show')">
        <a @click="showInfoModal(record.id)">{{ msisdn }}</a>
      </div>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 详情模态框 -->
    <show-agents-sms-reception-record
      v-if="isShowInfoModal"
      :visible.sync="isShowInfoModal"
      :id="showingId"
    />
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'
import { findAgentsSmsReceptionRecords } from '@/api/agents_sms_reception_record'
import SearchSmsReceptionRecord from '@/views/smses/sms_reception_records/Search'
import Pagination from '@/components/Pagination'

export default {
  name: 'AgentsSmsReceptionRecordList',
  components: {
    Pagination,
    SearchSmsReceptionRecord,
    ShowAgentsSmsReceptionRecord: () => import('@/views/smses/template_records/Show')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowInfoModal: false, // 是否显示详情弹窗
      showingId: 0,
      pagination: {
        total_count: 0
      },
      query: {
        effective: '1',
        per_page: 10
      },
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    agentId() {
      if (this.$route.query.agent_id) {
        return parseInt(this.$route.query.agent_id)
      }

      return this.$store.getters.userAgentId
    },

    agentType() {
      return parseInt(this.$store.getters.userAgentType)
    },

    columns() {
      var columnArr = [
        {
          title: 'MSISDN',
          dataIndex: 'msisdn',
          fixed: 'left'
        },
        {
          title: '内容',
          dataIndex: 'content'
        },
        {
          title: '时间',
          dataIndex: 'created_at'
        }
      ]

      return columnArr
    }
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    showInfoModal(recordId) {
      this.showingId = recordId
      this.isShowInfoModal = true
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findAgentsSmsReceptionRecords(
        Object.assign({ agent_id: this.agentId }, this.query, this.sort)
      ).then((res) => {
        this.data = res.data
        this.pagination = res.pagination
        this.loading = false
      })
    }
  }
}
</script>
