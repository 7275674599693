import request from '@/utils/request'

// 查询客户短信上行记录
export function findAgentsSmsReceptionRecords(params) {
  return request({
    url: `/customer/agents_sms_reception_records`,
    method: 'get',
    params: params
  })
}

// 查询单个短信上行记录
export function findAgentsSmsReceptionRecord(id) {
  return request({
    url: `/customer/agents_sms_reception_records/${id}`,
    method: 'get'
  })
}
